<template>
  <component :is="featureData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="featureData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Funcionalidade não encontrada
        <b-link class="alert-link" :to="{ name: 'apps-feature-list' }">
          Lista de funcionalidades
        </b-link>
        for other feature.
      </div>
    </b-alert>

    <template v-if="featureData">
      <div>
        <b-card title="Editar Funcionalidade">
          <b-row>
            <b-col md="6">
              <b-form-group label="Nome" label-for="nameFeature">
                <b-form-input v-model="featureData.name" />
                <!-- multiple select -->
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Identificador" label-for="membersFeature">
                <b-form-input v-model="featureData.feature_id" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Descrição" label-for="nameFeature">
                <b-form-textarea v-model="featureData.description" rows="4" />
                <!-- multiple select -->
              </b-form-group>
            </b-col>
          </b-row>
          <br />
          <div class="text-right">
            <b-button
              variant="primary"
              @click="updateFeature()"
              :to="{ name: 'apps-feature-list' }"
            >
              Salvar
            </b-button>
          </div>
        </b-card>
      </div>
    </template>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    BFormTextarea,
  },

  data() {
    return {
      userData: store.state.user.userData,
    };
  },

  methods: {
    updateFeature() {
      axios({
        method: "put",
        url: `${URL_API}feature/${this.featureData.id}`,
        headers: getHeader(this.userData),
        data: this.featureData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updatedB"),
          },
        });
      });
    },
  },

  setup() {
    const featureData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}feature/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        featureData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          featureData.value = undefined;
        }
      });

    return {
      featureData,
      vSelect,
    };
  },
};
</script>
